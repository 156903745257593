body {
  margin: 0;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*要素のフォントサイズやマージン・パディングをリセットしています*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/*行の高さをフォントサイズと同じにしています*/
body {
    line-height:1;
}

/*新規追加要素のデフォルトはすべてインライン要素になっているので、section要素などをブロック要素へ変更しています*/
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}

/*nav要素内ulのマーカー（行頭記号）を表示しないようにしています*/
/*nav ul {
    list-style:none;
}*/
ol, ul {
    list-style: none;
}

/*引用符の表示が出ないようにしています*/
blockquote, q {
    quotes:none;
}

/*blockquote要素、q要素の前後にコンテンツを追加しないように指定しています*/
blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

/*a要素のフォントサイズなどをリセットしフォントの縦方向の揃え位置を親要素のベースラインに揃えるようにしています*/
a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* ins要素のデフォルトをセットし、色を変える場合はここで変更できるようにしています */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* mark要素のデフォルトをセットし、色やフォントスタイルを変える場合はここで変更できるようにしています
また、mark要素とは、文書内の検索結果で該当するフレーズをハイライトして、目立たせる際に使用するようです。*/
mark {
    background-color:#ff9;
    color:#000;
    font-style:italic;
    font-weight:bold;
}

/*テキストに打ち消し線が付くようにしています*/
del {
    text-decoration: line-through;
}

/*IEではデフォルトで点線を下線表示する設定ではないので、下線がつくようにしています
また、マウスオーバー時にヘルプカーソルの表示が出るようにしています*/
abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

/*隣接するセルのボーダーを重ねて表示し、間隔を0に指定しています*/
table {
    border-collapse:collapse;
    border-spacing:0;
}

/*水平罫線のデフォルトである立体的な罫線を見えなくしています*/
hr {
    display:block;
    height:1px;
    border:0;
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

/*縦方向の揃え位置を中央揃えに指定しています*/
input, select {
    vertical-align:middle;
}

/*画像を縦に並べた時に余白が出ないように*/
img {
    vertical-align: top;
    font-size: 0;
    line-height: 0;
}

/*box-sizingを全ブラウザに対応*/
*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

/* HELP */
::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-track {
	background: #1D292E;
}
::-webkit-scrollbar-thumb {
  	background-color: #707070;
  	border-radius: 20px;
}

html{
	scroll-behavior: smooth;
	-webkit-scroll-behavior: smooth;
}
body{
	background: #3b4043;
	letter-spacing: .05em;
	line-height: 1.8em;
	font-family: "Noto Sans JP";
}
header{
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	background: #1D292E;
	box-shadow: 0px 3px 6px #00000029;
	height: 95px;
	z-index: 15;
}
.header-inner{
	max-width: 1160px;
	position: relative;
	margin: 0px auto;
	height: 95px;
}
.header-logo{
	height: 67px;
	vertical-align: middle;
}
.help-title{
	padding: 0px 20px 0px 0px;
	margin: 0px;
	line-height: 80px;
	font-size: 2.5em;
	color: #444;
}
.help-glonav{
	padding: 0;
	margin: 0;
	list-style: none;
	display: table;
	position: absolute;
	right: 0px;
	top: 0;
	height: 100%;
}
.help-glonav-menu{
	display: table-cell;
	vertical-align: middle;
	padding-left: 30px;
}
.help-glonav-control{
	display: none;
    position: absolute;
    right: 10px;
    top: 5px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    vertical-align: middle;
	text-align: center;
}
.help-glonav-close,
.help-glonav-menu.sp{
	display: none;
}
.help-glonav-icon{
	height: 40px !important;
}
.help-glonav-link{
	text-decoration: none;
	color: #fff;
	display: inline-block;
}
.help-glonav-text,
.help-glonav-inactive{
	font-size: 18px;
	line-height: 1;
	padding-bottom: 3px;
	position: relative;
}
.help-glonav-inactive{
	color: #707070;
}
.help-glonav-text:after{
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0%;
	height: 1px;
	background: #fff;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
}
.help-glonav-inactive:after{
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background: #707070;
}
.help-glonav-link:hover .help-glonav-text:after{
	width: 100%;
}
.help-inner{
	position: relative;
	max-width: 1160px;
	margin: 0 auto;
}
.help-menu-wrap{
	position: fixed;
	max-width: 1160px;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	height: 100%;
	z-index: 2;
	pointer-events: none;
}
.help-menu{
	position: absolute;
	left: 0px;
	top: 100px;
	width: 210px;
	color: #fff;
	margin: 0;
	bottom: 20px;
}
.header-page-title{
	margin: 40px 0px;
}
.header-page-title img{
	height: 48px;
}
.help-menu-list{
	position: absolute;
	left: 0;
	right: 0;
	border: 1px solid #707070;
	padding: 20px;
	border-radius: 10px;
	top: 120px;
	bottom: 0px;
	max-height: 60%;
}
.help-menu-title{
	margin: 10px 0px 10px 0px;
	font-size: 24px;
}
.help-menu-icon{
	fill: #FFF;
	display: none;
}
.help-menu-title-text{
	line-height: 1;
}
.help-anchor{
	padding: 0 0 50px 0;
	margin: 0;
	list-style: none;
	position: absolute;
	left: 20px;
	top: 68px;
	bottom: 20px;
	right: 20px;
	overflow-y: auto;
	pointer-events: auto;
}
.help-menu-list:after{
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100px;
	background: rgb(59,64,67);
	background: -webkit-linear-gradient(bottom, rgba(59,64,67,1) 0%, rgba(59,64,67,0) 100%);
	background: -o-linear-gradient(bottom, rgba(59,64,67,1) 0%, rgba(59,64,67,0) 100%);
	background: linear-gradient(to top, rgba(59,64,67,1) 0%, rgba(59,64,67,0) 100%);
	z-index: 1;
	border-radius: 0 0 10px 10px;
}
.help-anchor-li{
	border-bottom: 1px dotted #707070;
}
.help-anchor-link{
	text-decoration: none;
	color: #fff;
	display: inline-block;
	padding: 10px 0px 10px 0px;
	cursor: pointer;
	cursor: hand;
}
.help-anchor-link-text{
	display: inline-block;
	line-height: 1;
	padding-bottom: 5px;
	font-size: 18px;
	position: relative;
	z-index: 1;
}
.help-anchor-link-text:after{
	content: "";
	display: block;
	height: 10px;
	background: #004bb1;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	width: 0px;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
}
.help-anchor-link:hover .help-anchor-link-text:after{
	width: 100%;
}
.help-contents{
	padding: 220px 0px 40px 300px;
}
.command-guide{
	font-size: 18px;
	color: #fff;
	margin: 0px 0px 40px 0px;
}
.command-title{
	color: #fff;
	margin: 0px 0px 40px 0px;
	font-size: 38px;
}
.command-title-inner{
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 0px 10px;
}
.command-title-inner:before{
	content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 10px;
  background: #004bb1;
  bottom: -10px;
  z-index: -1;
}
.command-bubble{
	text-align: left;
	padding: 10px 20px 10px 50px;
	margin-bottom: 10px;
	position: relative;
	display: block;
	border-radius: 0px 0px 0px 10px;
	border-bottom: 1px solid #707070;
}
.command-bubble-icon-wrap{
	display: inline-block;
	width: 40px;
	height: 40px;
	position: absolute;
	left: 0;
	bottom: -10px;
	border-radius: 10px;
	text-align: center;
	background: #707f89;
}
.command-bubble-icon{
  fill: #fff;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}
.command-bubble-text{
	display: inline-block;
	border-radius: 0px 10px 10px 10px;
	color: #e0e0e0;
	position: relative;
	font-size: 18px;
	font-style: italic;
}
.command-bubble-text-component{
	display: block;
}
.command-exp{
	color: #fff;
	font-size: 18px;
	margin-top: 20px;
	margin-bottom: 80px;
}
.command-exp > a{
  color: #fff;
  text-decoration: underline;
}
footer{
	text-align: center;
	color: #fff;
	font-size: 18px;
	transition: all .3s ease;
	-webkit-transition: all .3s ease;
	padding: 40px 0px;
	max-width: 1160px;
	margin: 0 auto;
}
@media (max-width: 1240px){
	.help-title{
		padding-left: 40px;
	}
	.help-glonav{
		padding-right: 40px;
	}
	.help-menu{
		left: 40px;
	}
	.help-contents{
		padding-right: 40px;
	}
}
@media only screen and (max-width: 800px) {
	header{
		height: 60px;
	}
	.header-logo{
		height: 40px;
		vertical-align: top;
		margin-top: 10px;
	}
	.help-title,
	.header-inner{
		height: 60px;
	}
	.help-glonav-text{
		font-size: 12px;
	}
	.header-page-title{
		margin-top: 0;
	}
	.header-page-title img{
		height: 35px;
	}
	.help-menu{
		width: 180px;
	}
	.help-menu-list{
		top: 70px;
	}
	.help-menu-title{
		font-size: 18px;
	}
	.help-anchor-link-text{
		font-size: 16px;
	}
	.help-contents{
		padding: 175px 40px 40px 260px;
	}
	footer{
		font-size: 14px;
	}
}
@media (max-width: 640px){
	header{
		height: 50px;
		z-index: 11;
	}
	.help-title{
		padding-left: 20px;
		text-align: center;
	}
	.header-logo{
		margin-top: 10px;
		height: 26px;
	}
	.help-glonav{
		padding: 0px;
		position: fixed;
		left: 100%;
		top: 0;
		height: 100%;
		width: 100%;
		background: #004bb1;
		display: block;
		transition: .5s all ease;
		-webkit-transition: .5s all ease;
		z-index: 10;
	}
	.help-glonav.open{
		left: 0%;
	}
	.help-glonav-control{
		display: block;
	}
	.help-glonav-menu{
		display: block;
		padding: 0px;
		margin: 0px 30px;
	}
	.help-glonav-close,
	.help-glonav-menu.sp{
		display: block;
	}
	.help-glonav-title{
		display: block;
		text-align: center;
		padding: 20px 0px 10px 0px;
		border-bottom: 1px dashed #fff;
	}
	.help-glonav-link{
		display: block;
		padding: 10px 0px;
		border-bottom: 1px dashed #fff;
	}
	.help-glonav-text{
		font-size: 18px;
		font-weight: normal;
	}
	.help-glonav-inactive{
		padding: 10px 0px;
		line-height: 30px;
		display: block;
		font-size: 18px;
		color: #FFF;
		border-bottom: 1px dashed #FFF;
	}
	.help-glonav-inactive:before{
		content: "";
		border-left: 5px solid #fff;
		border-top: 5px solid rgba(255, 255, 255, 0);
		border-bottom: 5px solid rgba(255, 255, 255, 0);
		border-right: 0;
		height: 0;
		display: inline-block;
		margin-right: 10px;
		vertical-align: middle;
	}
	.help-glonav-control img{
		margin-top: 7px;
	}
	.help-glonav-close{
		position: absolute;
		left: 30px;
		bottom: 40px;
	}
	.help-glonav-close-icon{
		fill: #fff;
	}
	.help-menu-wrap{
		position: static;
		padding: 80px 20px 20px 20px;
		transform: none;
		height: auto;
	}
	.help-menu{
		position: static;
	}
	.header-page-title img{
		height: 30px;
	}
	.header-page-title{
		margin-bottom: 0px;
	}
	.help-menu-title{
		display: none;
	}
	.help-menu-list{
		background: #1D292E;
		position: fixed;
		left: 0;
		bottom: 0;
		right: 0;
		top: auto;
		border-radius: 0;
		border: none;
		z-index: 2;
		padding: 10px 50% 10px 50%;
		overflow-y: auto;
		pointer-events: auto;
	}
	.help-menu-list::-webkit-scrollbar,
	.help-menu-list::-webkit-scrollbar-track,
	.help-menu-list::-webkit-scrollbar-thumb {
		display: none;
	}
	.help-menu-list{
		scroll-behavior: smooth;
	}
	.help-menu-list:after{
		display: none;
	}
	.help-anchor{
		display: table;
		padding: 0px;
		position: static;
		top: 0;
		transition: .5s all ease;
		-webkit-transition: .5s all ease;
	}
	.help-anchor-li{
		display: table-cell;
		border-bottom: none;
	}
	.help-anchor-link{
		word-break: keep-all;
		display: inline-block;
		padding: 10px 10px;
	}
	.help-anchor-link.active .help-anchor-link-text{
		color: #fff;
		font-size: 20px;
		border-bottom: 1px solid #fff;
		padding-bottom: 5px;
	}
	.help-anchor-link-text{
		color: #707070;
		pointer-events: none;
		transition: .2s all ease;
		-webkit-transition: .2s all ease;
	}
	.help-anchor-link-text:after{
		display: none;
	}
	.help-contents{
		padding: 0px 20px;
	}
  .command-guide{
    font-size: 12px;
  }
	.command-title{
		margin-bottom: 10px;
	}
	.command-title-inner{
		font-size: 24px;
		display: block;
		padding: 0px;
		border-bottom: 1px solid #fff;
		padding-bottom: 3px;
	}
	.command-title-inner:before{
		display: none;
	}
	.command-bubble{
		padding-left: 40px;
	}
	.command-bubble-text-component{
		font-size: 12px;
	}
	.command-bubble-icon-wrap{
		width: 30px;
		height: 30px;
	}
	svg.command-bubble-icon{
		width: 18px !important;
		height: 18px !important;
    transform: translateY(-60%);
    -webkit-transform: translateY(-60%);    
	}
	.command-exp{
		font-size: 12px;
		margin-bottom: 40px;
	}
	footer{
		font-size: 12px;
		padding-bottom: 80px;
	}
}


/* CATALOG */
.catalog-mv{
	margin-top: 95px;
	height: 400px;
	background: url(/assets/img/main/PC_catalog_MV_1280x400.jpg) no-repeat center center;
	background-size: auto 400px;
	position: relative;
}
.catalog-mv:after{
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100px;
	background: transparent linear-gradient(180deg, #3B404300 0%, #3B40430D 38%, #3B4043CB 76%, #3B4043 100%) 0% 0% no-repeat padding-box;
}
.catalog-mv-inner{
	width: 100%;
	max-width: 1160px;
	margin: 0 auto;
	position: relative;
}
.catalog-mv-title{
	position: absolute;
	right: 40px;
	top: 148px;
}
.catalog-mv-title img{
  vertical-align: top;
}
.catalog-mv-title img:first-child{
  height: 40px;
}
.catalog-mv-title img:last-child{
  height: 45px;
  margin-left: 40px;
}
.catalog-mv-text{
	margin: 0;
	position: absolute;
	right: 40px;
	top: 216px;
	color: #fff;
	font-size: 24px;
}
@media (max-width: 800px){
  .catalog-mv{  
    margin-top: 60px;
  }
}
@media (max-width: 640px){
  .catalog-mv{
    margin-top: 50px;
    height: 230px;
    background-size: auto 100%;
  }
  .catalog-mv:after{
    display: none;
  }
  .catalog-mv-title{
    top: 50px;
    text-align: right;
    width: 128px;
  }
  .catalog-mv-title img{
    display: block;
    height: 30px !important;
    margin-bottom: 20px;
    margin-left: 0 !important;
    clear: both;
  }
  .catalog-mv-text{
    top: 150px;
    width: 128px;
    font-size: 16px;
    line-height: 1.5;
  }
}

/* ERROR */
.error-inner{
  padding: 150px 0px 40px 140px;
  max-width: 1160px;
  min-height:  400px;
  margin: 0 auto;
  color: #fff;
  background: url(/assets/img/svg/404.svg) no-repeat left 140px;
  background-size: auto 115px;
}
.error-title{
  color: #fff;
  margin: 0 0 40px;
  font-size: 38px;
}
.error-title-inner{
  isplay: inline-block;
  position: relative;
  z-index: 1;
  padding: 0 10px;
}
.error-title-inner:after{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 10px;
  background: #004bb1;
  bottom: 0px;
  z-index: -1;
}
.error-text{
  font-size: 18px;
  color: #fff;
  margin: 0 0 40px;
}
.error-credit{
  font-size: 12px;
  color: #888;
  font-style: italic;  
}
.error-credit a{
  color: #888;
  text-decoration: underline;
}
@media (max-width: 1240px){
  .error-inner{
    padding-right: 40px;
    padding-left: 200px;
    background-position: 40px 140px;
  }
}
@media (max-width: 640px){
  .error-inner{
    background-position: center 100px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 250px;
    text-align: center;
  }
}

.text-contents{
  max-width: 1160px;
  margin: 0 auto;
  color: #fff;
  text-align: left;
  padding-top: 150px;
}
.text-contents-title{
  color: #fff;
  margin: 0 0 40px;
  font-size: 38px;
  line-height: 1.5;
}
.text-page-desc{
  padding-bottom: 30px;
}
.text-part{
  margin-bottom: 40px;
}
.text-heading{
  font-size: 20px;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
}
.text-heading > span{
  position: absolute;
  left: 0;
}
.text-desc{
  padding-left: 30px;
}
.text-update-date{
  text-align: right;
}
@media (max-width: 1240px){
  .text-contents{
    padding: 150px 40px 0px 40px;
  }
}
@media (max-width: 800px){
  .text-page-desc,
  .text-desc{
    font-size: 14px;
  }
  .text-contents-title{
    font-size: 28px;
  }
  .text-contents{
    padding: 70px 20px 0px 20px;
  }
}