@charset "UTF-8";
@keyframes slideFromRight{
	0%{
		left: -50px;
	}
	100%{
		left: 20px;
	}
}
@keyframes sinkOut{
	0%{
		height: 100%;
		opacity: 1;
		visibility: visible;
	}
	50%{
		height: 100%;
		opacity: 1;
		visibility: visible;
	}
	100%{
		height: 0%;
		opacity: 0;
		visibility: hidden;
	}
}
@keyframes slideIn{
	0%{
		transform: translateX(-1000px);
	}
	100%{
		transform: translateX(0px);
	}
}
@keyframes popUpShake{
	0%{
		transform: scale(0.5);
		-webkit-transform: scale(0.5);
	}
	60%{
		transform: scale(1.05);
		-webkit-transform: scale(1.05);
	}
	70%{
		transform: scale(1);
		-webkit-transform: scale(1);
	}
	80%{
		transform: scale(0.95);
		-webkit-transform: scale(0.95);
	}
	90%{
		transform: scale(1.05);
		-webkit-transform: scale(1.05);
	}
	100%{
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}
.trixa-body{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
}
.trixa-loading{
	background: #09212A;
	position: fixed;
	left: 0;
	top: 50%;
	width: 100%;
	height: 0%;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transform-origin: 50% 50%;
	-webkit-transform-origin: 50% 50%;
	transform: translateY(-50%);
	animation: 2s sinkOut;
	-webkit-animation: 2s sinkOut;
	overflow: hidden;
}
.trixa-loading-img{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-55%);
	-webkit-transform: translateX(-50%) translateY(-55%);
	width: 150px;
}
.trixa-loading-img img{
	width: 33%;
}
.trixa-loading-text{
	color: #fff;
}
.trixa-main{
	background: url('/assets/img/main/PC_MV_1280x800.jpg') no-repeat center center;
	background-size: auto 100%;
	height: 100vh;
	min-height: 600px;
	position: relative;
}
.trixa-main-top{
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: #09212A66;
}
.trixa-inner{
	max-width: 1160px;
	margin: 0 auto;
}
.trixa-menu{
	padding: 35px 20px;
	text-align: right;
	position: relative;
	z-index: 2;
}
.trixa-menu-a{
	text-decoration: none;
	color: #fff;
	font-size: 18px;
	margin-left: 20px;
	position: relative;
}
.trixa-menu-title,
.trixa-menu-a.sp,
.trixa-menu-control,
.close-icon{
	display: none;
}
.trixa-menu-a:after{
	content: "";
	position: absolute;
	left: 0;
	bottom: -5px;
	height: 1px;
	background: #fff;
	width: 0px;
	transition: .2s all ease;
	-webkit-transition: .2s all ease;
}
.trixa-menu-a:hover:after{
	width: 100%;
}
.trixa-main-hello{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	bottom: 200px;
}
.trixa-main-text{
	position: absolute;
	bottom: 250px;
}
.trixa-main-text-01,
.trixa-main-text-02,
.trixa-main-text-03{
	display: inline-block;
	margin-top: 50px;
	position: relative;
	padding: 0px 10px;
	z-index: 1;
}
.trixa-main-text-01:before,
.trixa-main-text-02:before,
.trixa-main-text-03:before{
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: -10px;
	height: 15px;
	background: rgba(0, 0, 0, .1);
	z-index: -1;
}
.trixa-main-text-01:before{
	animation-name: slideIn;
	animation-duration: 2s;
}
.trixa-main-text-01 img{
	animation-name: slideIn;
	animation-duration: 2.5s;
	animation-delay: .3s;
}
.trixa-main-text-02:before{
	animation-name: slideIn;
	animation-duration: 2s;
	animation-delay: .3s;
}
.trixa-main-text-02 img{
	animation-name: slideIn;
	animation-duration: 2.5s;
	animation-delay: .6s;
}
.trixa-main-text-03:before{
	animation-name: slideIn;
	animation-duration: 2s;
	animation-delay: .6s;
}
.trixa-main-text-03 img{
	animation-name: slideIn;
	animation-duration: 2.5s;
	animation-delay: 1s;
}
.trixa-scroll{
	width: 200px;
	border-bottom: 1px solid #fff;
	position: absolute;
	display: block;
	left: 50%;
	bottom: 0px;
	font-size: 18px;
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	color: #fff;
	margin-left: -100px;
}
.trixa-what{
	background: #3B4043;
}
.trixa-inner.about{
	padding: 150px 0px 100px 0px;
	display: table;
	width: 100%;
}
.trixa-what-img{
	transform: scale(0);
	-webkit-trasform: scale(0);
}
.trixa-what.visible .trixa-what-img{
	animation-name: popUpShake;
	-webkit-animation-name: popUpShake;
	animation-duration: .8s;
	-webkit-animation-duration: .8s;
	animation-fill-mode: forwards;
	-webkit-animation-fill-mode: forwards;
}
.trixa-left-part,
.trixa-right-part{
	display: table-cell;
	vertical-align: top;
}
.trixa-left-part{
	padding-right: 50px;
}
.trixa-right-part{
	padding-top: 100px;
}
.trixa-subtitle{
	margin-bottom: 30px;
}
.trixa-subtitle-inner span{
	display: inline-block;
}
.trixa-subtitle img,
.trixa-subtitle-inner span{
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
	-webkit-transform: translateY(20px);
	transition: 1s all ease;
	-webkit-transition: 1s all ease;
}
.visible .trixa-subtitle img,
.visible .trixa-subtitle-inner span{
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	-webkit-transform: translateY(0);
	transition-delay: 1.5s;
}
.visible .trixa-subtitle-inner span{
	transition-delay: 0.5s;
}
.trixa-subtitle-inner{
	position: relative;
	display: inline-block;
	padding: 0px 10px 5px 10px;
	z-index: 1;
}
.trixa-subtitle-inner:before{
	content: "";
	position: absolute;
	left: 0;
	width: 100%;
	height: 20px;
	background: #004BB1;
	bottom: 0;
	z-index: -1;
}
.trixa-what .trixa-subtitle-inner:before,
.trixa-spec .trixa-subtitle-inner:before,
.trixa-native .trixa-subtitle-inner:before,
.trixa-start .trixa-subtitle-inner:before{
	transition: .5s all ease;
	-webkit-transition: .5s all ease;
	width: 0%;
}
.trixa-what.visible .trixa-subtitle-inner:before{
	width: 100%;
	transition-delay: 1s;
}
.trixa-native.visible .trixa-subtitle-inner:before,
.trixa-spec.visible .trixa-subtitle-inner:before,
.trixa-start.visible .trixa-subtitle-inner:before{
	width: 100%;
}
.trixa-desc{
	font-size: 18px;
	color: #fff;
	line-height: 2em;
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
	-webkit-transform: translateY(20px);
	transition: 1s all ease;
	-webkit-transition: 1s all ease;
}
.trixa-desc > a,
.trixa-desc > a:visited,
.trixa-desc > a:focus{
	color: #fff;
	margin: 0 5px;
}
.visible .trixa-desc{
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	-webkit-transform: translateY(0);
	transition-delay: 2s;
}
.trixa-spec.visible .trixa-desc,
.trixa-native.visible .trixa-desc,
.trixa-start.visible .trixa-desc{
	transition-delay: 1s;
}
.trixa-btn-wrap{
	margin: 0px;
	padding: 50px 0px 0px 0px;
	text-align: center;
	clear: both;
}
.trixa-btn{
	display: inline-block;
	padding: 20px 30px;
	border: 1px solid #fff;
	border-radius: 40px;
	text-decoration: none;
	color: #fff;
	font-size: 24px;
	transition: .2s all ease;
	-webkit-transition: .2s all ease;
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.trixa-btn{
	transform: scale(0);
	-webkit-trasform: scale(0);
}
.visible .trixa-btn{
	animation-name: popUpShake;
	-webkit-animation-name: popUpShake;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-delay: 2s;
	-webkit-animation-delay: 2s;
	animation-fill-mode: forwards;
	-webkit-animation-fill-mode: forwards;
}
.trixa-spec.visible .trixa-btn{
	animation-delay: 4s;
	-webkit-animation-delay: 4s;
}
.trixa-native.visible .trixa-btn{
	animation-delay: 1.5s;
	-webkit-animation-delay: 1.5s;
}

.trixa-btn:before{
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 100%;
	transition: .2s all ease;
	-webkit-transition: .2s all ease;
	background: rgba(0, 0, 0, .2);
	z-index: -1;
}
.trixa-btn:hover{
	box-shadow: 0px 8px 8px #00000066;
}
.trixa-btn:hover:before{
	width: 100%;
}
.trixa-btn:after{
	content: "";
	display: inline-block;
	border-left: 10px solid #fff;
	border-top: 10px solid rgba(0, 0, 0, 0);
	border-bottom: 10px solid rgba(0, 0, 0, 0);
	border-right: 0;
	margin-left: 10px;
}
.trixa-spec{
	background: #707F89;
}
.trixa-inner.spec{
	padding: 80px 0px;
}
.trixa-subtitle.spec,
.trixa-subtitle.native,
.trixa-subtitle.start{
	color: #fff;
	font-size: 38px;
	text-align: center;
	font-weight: normal;
}
.trixa-subtitle.spec .trixa-subtitle-inner,
.trixa-subtitle.native .trixa-subtitle-inner,
.trixa-subtitle.start .trixa-subtitle-inner{
	padding-bottom: 10px;
}
.trixa-spec .trixa-desc{
	text-align: center;
}
.trixa-catalog{
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
	flex-flow: row wrap;
	align-content: stretch;
	padding: 40px 0px 0px 0px;
}
.trixa-quiz{
	width: 45%;
	margin-bottom: 40px;
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
    -webkit-transform: translateY(20px);
	transition: 1s all ease;
	-webkit-transition: 1s all ease;	
}
.visible .trixa-quiz{
	opacity: 1;
	visibility: visible;
	transform: translateY(0px);
    -webkit-transform: translateY(0px);	
}
.visible .trixa-quiz:nth-child(1){
	transition-delay: 1.7s;
	-webkit-transition-delay: 1.7s;	
}
.visible .trixa-quiz:nth-child(2){
	transition-delay: 2.2s;
	-webkit-transition-delay: 2.2s;	
}
.visible .trixa-quiz:nth-child(3){
	transition-delay: 2.7s;
	-webkit-transition-delay: 2.7s;	
}
.visible .trixa-quiz:nth-child(4){
	transition-delay: 3.2s;
	-webkit-transition-delay: 3.2s;	
}

.trixa-item{
	display: block;
	padding: 10px 0px 0px 0px;
	margin: 0;
	position: relative;
}
.trixa-item-img{
	width: 220px;
	position: absolute;
	z-index: 1;
}
.trixa-item-thumbs{
	width: 100%;
	border-radius: 10px;
	box-shadow: 0 8px 8px #0000003D;
}
.trixa-item-new{
	position: absolute;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	font-size: 10px;
	color: #3B4043;
	border-radius: 50%;
	background: #FFC500;
	left: -20px;
	top: -20px;
}
.trixa-item-detail{
	margin: 65px 0px 0px 175px;
	padding: 30px 30px 30px 80px;
	background: #1D292E;
	border-radius: 10px;
	box-shadow: 0px 4px 8px #00000029;
	color: #fff;
	position: relative;
}
.trixa-item-title{
	font-size: 18px;
	margin: 0px 0px 10px 0px;
	word-break: break-all;
}
.trixa-item-title-id {
  color: #ffc525;
}
.trixa-item-price,
.trixa-item-creator,
.trixa-item-desc{
	font-size: 12px;
	margin: 5px 0px 0px 0px;
	word-break: break-all;
}
.trixa-cat-list{
  padding: 10px 0px;
}
.trixa-cat{
  display: inline-block;
  font-size: 12px;
  background: #ffc525;
  padding: 3px 10px;
  border-radius: 4px;
  margin-right: 5px;
  line-height: 1.5;
  color: #444;
}
.trixa-item-btn{
	display: block;
	position: absolute;
	background: #E44040;
	color: #fff;
	padding: 10px 20px;
	border-radius: 6px;
	text-decoration: none;
	right: -20px;
	bottom: -20px;
	font-size: 18px;
	box-shadow: 0px 8px 8px #0000003D;
	transition: .2s all ease;
	-webkit-transition: .2s all ease;
	overflow: hidden;
	z-index: 1;	
}
.trixa-item-btn:before{
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 100%;
	transition: .2s all ease;
	-webkit-transition: .2s all ease;
	background: rgba(255, 255, 255, .2);
	z-index: -1;
}
.trixa-item-btn:hover:before{
	width: 100%;
}
.trixa-btn-icon{
	vertical-align: middle;
	margin-right: 5px;
}
.trixa-native{
	background: url('/assets/img/main/PC_scondMV_1280x800_.jpg') no-repeat center center;
	background-size: 100% auto;
	position: relative;
}
.trixa-native:after{
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: #3B4043AA;
	z-index: 0;
}
.trixa-inner.native{
	position: relative;
	z-index: 1;
	padding: 80px 0px;
}
.trixa-subtitle.native{
	text-align: right;
}
.trixa-right-native{
	width: 450px;
	float: right;
}
.trixa-right-native .trixa-desc{
	padding: 50px 0px;
}
.trixa-start{
	padding: 80px 0px;
	background: #707F89;
	text-align: center;
}
.trixa-start .trixa-desc{
	padding: 85px 0px 50px 0px;
}
.trixa-skill-url{
	width: 200px;
	display: block;
	transform: scale(0);
	-webkit-transform: scale(0);
	margin: 0 auto;
}
.visible .trixa-skill-url{
	animation-name: popUpShake;
	animation-duration: 1s;
	animation-delay: 1.5s;
	animation-fill-mode: forwards;
}
.trixa-social{
	padding-top: 50px;
}
.trixa-social-icon{
	width: 38px;
	height: 38px;
	display: inline-block;
	margin: 0px 5px;
	border-radius: 50%;
	line-height: 38px;
	text-align: center;
	background: #98A6B5;
	color: #fff;
	box-shadow: 0px 3px 6px #00000029;
	position: relative;
	overflow: hidden;
	z-index: 1;
	transition: 1s all ease;
	-webkit-transition: 1s all ease;
	opacity: 0;
	visibility: hidden;
}
.trixa-social-icon:nth-child(1){
	transition-delay: 3s;
	-webkit-transition-delay: 3s;
}
.trixa-social-icon:nth-child(2){
	transition-delay: 3.5s;
	-webkit-transition-delay: 3.5s;
}
.visible .trixa-social-icon{
	opacity: 1;
	visibility: visible;
}
.trixa-social-icon:before{
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 100%;
	transition: .2s all ease;
	-webkit-transition: .2s all ease;
	background: rgba(0, 0, 0, .2);
	z-index: -1;
}
.trixa-social-icon:hover:before{
	width: 100%;
}
.trixa-social-icon .trixa-btn-icon{
	vertical-align: middle;
	margin-right: 0;
}
.trixa-copy{
	padding-top: 80px;
	color: #fff;
	transition: 1s all ease;
	-webkit-transition: 1s all ease;
	opacity: 0;
	visibility: hidden;
	transition-delay: 4s;
	-webkit-transition-delay: 4s;
}
.visible .trixa-copy{
	opacity: 1;
	visibility: visible;
}
.trixa-bottom-link{
  visibility: hidden;
}
.visible .trixa-bottom-link{
  transition: all .2s ease;
	-webkit-transition: all .2s ease;
  visibility: visible;
  transition-delay: 4s;
  -webkit-transition-delay: 4s;
}
.trixa-bottom-a{
  font-size: 12px;
  color: #fff;
  display: inline-block;
  margin: 30px 5px 0px 5px;
  text-decoration: none;
  line-height: 1.5;
}
.trixa-bottom-a:after{
  content: "";
  display: block;
  height: 1px;
  background: #fff;
  width: 0px;
  transition: all .2s ease;
	-webkit-transition: all .2s ease;
}
.trixa-bottom-a:hover:after{
  width: 100%;  
}
@media (min-aspect-ratio: 15/9) {
	.trixa-main{
		background-size: 100% auto;
	}
}
@media (max-width: 1240px){
	.trixa-inner{
		padding-left: 40px !important;
		padding-right: 40px !important;
	}
	.trixa-left-part{
		width: 40%;
	}
	.trixa-right-part{
		padding-top: 50px;
	}
	.trixa-what-img{
		width: 100%;
	}
	.trixa-native{
		background-size: auto 100%;
	}
	.trixa-item-img{
		width: 40%;
	}
	.trixa-item-detail{
		margin: 10% 0px 0px 30%;
		padding: 20px 20px 30px 15%;
	}
}
@media (max-width: 800px){
	.trixa-loading-img{
		width: 100px;
	}
	.trixa-menu{
		padding-left: 0px;
		padding-right: 0px;
	}
	.trixa-main-hello{
		bottom: 150px;
	}
	.trixa-main-hello img{
		width: 150px;
	}
	.trixa-subtitle{
		text-align: center;
	}
	.trixa-what.visible .trixa-subtitle-inner img{
		transition-delay: .5s;
	}
	.trixa-what.visible .trixa-subtitle-inner:before{
		transition-delay: 0s;
	}
	.trixa-what.visible .trixa-desc{
		transition-delay: 1s;
	}
	.trixa-what.visible .trixa-btn{
		transition-delay: 1.5s;
	}
	.trixa-inner.about{
		display: block;
		width: auto;
		padding-bottom: 60%;
		position: relative;
		padding-top: 100px;
	}
	.trixa-left-part{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		width: auto;
		padding: 80px 40px;
		text-align: center;
	}
	.trixa-what-img{
		max-width: 50%;
	}
	.trixa-quiz{
		width: 100%;
	}
	.trixa-item-img{
		width: 25%;
	}
	.trixa-item-detail{
		margin: 30px 0px 0px 20px;
		padding: 20px 20px 30px 25%;
	}
  .trixa-bottom-link{
    display: none;
  }
}
@media (max-width: 640px){
	html,
	.trixa-body{
		scroll-behavior: smooth;
	}
	.trixa-main{
		min-height: 0;
	}
	.trixa-menu-contents{
		position: fixed;
		left: 100%;
		top: 0;
		width: 100%;
		bottom: 0;
		background: #004BB1;
		z-index: 5;
		padding: 20px 30px;
		transition: .5s all ease;
		-webkit-transition: .5s all ease;
	}
	.trixa-menu-contents.visible{
		left: 0;
	}
	.trixa-menu{
		padding-top: 10px;
	}
	.trixa-menu-control{
		width: 40px;
		height: 40px;
		text-align: center;
		display: inline-block;
		line-height: 40px;
		margin-right: -10px;
	}
	.trixa-menu-control img{
		margin-top: 8px;
	}
	.trixa-menu-control.true{
		position: fixed;
		bottom: 30px;
		left: 20px;
		animation-name: slideFromRight;
		-webkit-animation-name: slideFromRight;
		animation-duration: .5s;
		-webkit-animation-duration: .5s;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
		background: rgba(0, 0, 0, .7);
		width: 50px;
		height: 50px;
		border-radius: 10px;
	}
	.trixa-menu-control.true img{
		margin-top: 12px;
	}
	.trixa-menu-title,
	.trixa-menu-a,
	.trixa-menu-a.sp{
		display: block;
		margin: 0;
	}
	.trixa-menu-a.pc{
		display: none;
	}
	.trixa-menu-a:before,
	.trixa-menu-a:after{
		display: none;
	}
	.trixa-menu-title{
		text-align: center;
		padding-bottom: 10px;
		border-bottom: 1px dashed #fff;
	}
	.close-icon{
		fill: #fff;
		position: absolute;
		bottom: 40px;
		left: 30px;
		display: block;
	}
	.trixa-menu-a{
		text-align: left;
		padding: 10px 0px;
		border-bottom: 1px dashed #fff;
		font-size: 18px;
	}
	.trixa-main-text{
		bottom: auto;
		top: 60px;
	}
	.trixa-main-text-01,
	.trixa-main-text-02,
	.trixa-main-text-03{
		height: 40px;
		margin-top: 0px;
		margin-bottom: 30px;
	}
	.trixa-main-text img{
		height: 100%;
		width: auto;
	}
	.trixa-inner{
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
	.trixa-scroll{
		left: auto;
		margin-left: 0;
		right: 20px;
		margin-right: -50px;
		width: 100px;
		font-size: 12px;
	}
	.trixa-inner.about{
		padding-top: 60px;
		padding-bottom: 105%;
	}
	.trixa-inner.spec{
		padding: 60px 0px;
	}
	.trixa-right-part{
		padding-top: 0px;
	}
	.trixa-subtitle-inner{
		display: block;
	}
	.trixa-subtitle-inner img{
		height: 30px;
		width: auto;
	}
	.trixa-subtitle-inner span{
		font-size: 24px;
	}
	.trixa-subtitle-inner:before{
		height: 9px;
	}
	.trixa-subtitle.spec .trixa-subtitle-inner, 
	.trixa-subtitle.native .trixa-subtitle-inner, 
	.trixa-subtitle.start .trixa-subtitle-inner{
		padding-bottom: 5px;
	}
	.trixa-left-part{
		padding: 40px 40px 60px 40px;
	}
	.trixa-what-img{
		max-width: 100%;
	}
	.trixa-desc{
		font-size: 14px;
	}
	.trixa-btn-wrap{
		padding-top: 40px;
	}
	.trixa-btn{
		padding: 5px 20px;
		font-size: 14px;
	}
	.trixa-btn:after{
		border-left-width: 5px;
    	border-top-width: 5px;
    	border-bottom-width: 5px;
	}
	.trixa-right-native{
		width: auto;
		text-align: center;
		float: none;
	}
	.trixa-subtitle.native{
		text-align: center;
	}
	.trixa-right-native .trixa-desc,
	.trixa-start .trixa-desc{
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.trixa-quiz{
		margin-bottom: 20px;
	}
	.trixa-item-img{
		width: 120px;
		height: 120px;
	}
	.trixa-item-detail{
		margin-left: 15px;
		border-radius: 5px;
		padding-left: 20px;
		line-height: 1.5em;
	}
	.trixa-item-new{
		left: auto;
		right: -13px;
		top: 15px;
		width: 26px;
		height: 26px;
		line-height: 26px;
	}
	.trixa-item-title{
		padding-left: 100px;
	}
	.trixa-item-creator{
		font-size: 12px;
		padding-left: 100px;
	}
	.trixa-item-price{
		font-size: 18px;
		text-align: right;
	}
	.trixa-item-btn{
		right: 20px;
		font-size: 16px;
		padding: 5px 20px;
	}
	
	.trixa-start,
	.trixa-social{
		padding-top: 60px;
	}
	.trixa-skill-url-holder{
		text-align: center;
	}
	.trixa-skill-url{
		margin-top: 20px;
		width: 100px;
		display: inline-block;
	}
	.trixa-url-icon{
		width: 100%;
	}
	.trixa-copy{
		padding-top: 40px;
		font-size: 12px;
	}
}
