$font-size: 16px;
$font-size-big: 18px;
$background-color-main: #282c34;
$color-main: #ffffff;
$margin-default: 8px;
$padding-default: 8px;
$padding-v-default: 8px 0;

@mixin fixed-footer() {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.App {
  text-align: left;
}

.App-Header {
  background-color: $background-color-main;
  color: $color-main;
  padding: $padding-default;
  text-align: center;
  img {
    height: 30px;
  }
}
.App-Contents {
  text-align: center;
  display: flex;
  padding: $padding-default;
  height: 500px;
}
.App-Footer {
  @include fixed-footer();
  background-color: $background-color-main;
  color: $color-main;
  padding: $padding-default;
  text-align: center;
}
.Page-Title {
  font-size: $font-size-big;
  padding: $padding-v-default;
}
